/* src/App.css */

/* Primary Colors */
:root {
    --primary-color: #3B80DF;
    --secondary-color: #BAD1CE;
    --background-color: white;
    --text-color-light: #2A2B2E;
  }
  
  /* General Styling */
  body {
    background-color: var(--background-color);
    color: var(--text-color-light);
    font-family: Arial, sans-serif;
  }
  
  /* Header */
  .header {
    background-color: var(--primary-color);
    padding: 20px;
    text-align: center;
  }
  
  .header img {
    max-width: 150px;
    height: auto;
  }
  
  .header h1 {
    color: var(--text-color-light);
    margin-top: 10px;
    font-size: 2rem;
  }
  
  /* Forms */
  .form-control {
    background-color: var(--secondary-color);
    color: var(--background-color);
    border: none;
    margin-bottom: 15px;
  }
  
  .form-control::placeholder {
    color: var(--background-color);
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .btn-primary:hover {
    background-color: darken(var(--primary-color), 10%);
  }
  
  .btn-secondary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--background-color);
  }
  
  .btn-info {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--text-color-light);
  }
  
  /* Device and Key List */
  .list-group-item {
    background-color: var(--secondary-color);
    color: var(--background-color);
    border: 1px solid var(--primary-color);
  }
  
  .btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
  }
  
  .btn-danger:hover {
    background-color: darken(#e74c3c, 10%);
  }
  
  /* Styling for Add Key Form */
  .add-key-form {
    margin-top: 20px;
  }
  
  .add-key-form input {
    margin-bottom: 10px;
  }
  
  .add-key-form button {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .add-key-form button:hover {
    background-color: #218838;
  }